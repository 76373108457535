/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import ploneCollectivevoltoAuthomatic from '@plone-collective/volto-authomatic';

const addonsInfo = [{"modulePath":"/app/node_modules/@plone/volto/packages/volto-slate/src","packageJson":"/app/node_modules/@plone/volto/packages/volto-slate/package.json","version":"16.20.8","isPublishedPackage":false,"isRegisteredAddon":false,"name":"@plone/volto-slate","addons":[]},{"name":"@plone-collective/volto-authomatic","version":"1.3.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/app/node_modules/@plone-collective/volto-authomatic/src","packageJson":"/app/node_modules/@plone-collective/volto-authomatic/package.json","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, ploneCollectivevoltoAuthomatic];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
